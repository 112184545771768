<template>
  <div>
    <el-form :model="formData" label-width="120px">
      <el-form-item v-for="(field, index) in formFields" :key="index" :label="field.WordTitle">
        <el-input
          type="textarea"
          :rows="5"
          v-model="formData[field.Namespace].WordContent"
        />
        <!-- 可以根据需要添加更多类型的表单控件 -->
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm">保存</el-button>
<!--        <el-button>取消</el-button>-->
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import axios from 'axios';
import {addProjectWordTitleContent, getProjectWordContent} from "@/api/api";

export default {
  data() {
    return {
      formData: {},
      formFields: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      let params = {};
      getProjectWordContent(params).then((res) => {
        if (res.data.Success) {
          this.formFields = res.data.Response;
          res.data.Response.forEach((itemElement) => {
            this.$set(this.formData, itemElement.Namespace, {
              Namespace: itemElement.Namespace,
              WordContent: itemElement.WordContent, // 确保 WordContent 有默认值
              WordTitle: itemElement.WordTitle,
              ProjectId: itemElement.ProjectId
            });
          });
          console.log(this.formFields, 'formFields');
        }
      });
    },
    async submitForm() {
	    const valuesArray = Object.values(this.formData);
	    addProjectWordTitleContent(valuesArray).then((res)=>{
				if (res.data.Success){
					this.$message.success('保存成功')
				}else {
					this.$message.error(res.data.Message)
				}
	    })
    }
  }
};
</script>

<style scoped lang="stylus">
.el-form {
  max-width:90%;
  margin: 0 auto;
}
</style>
